var wipwrp = window.wipwrp || {};

wipwrp.imageCropper = (function($,mzr,doc,win){
    var self = {};



    var FOTO_FILE_SIZE_LIMIT = 8 * 1024 * 1024; // 8MB

    var infoShownOnce = false;
    function _cropperData(obj) {
        return Object.assign({}, {
            fileName: "",
            fileSize: -1,
            uri: "",
            imgLoaded: false,
            prevSelection: null,
            selection: {
                x: "",
                y: "",
                w: "",
                h: "",
            }
        }, obj);
    }
    var cropperData = _cropperData();
    var cropperInstance = null;



    var imageInput$ = null;
    var imageOldInput$ = null;
    var cropInput$ = {
        x: null,
        y: null,
        w: null,
        h: null
    };

    var uploadTriggerBtn$ = null;
    var uploadTriggerBtnText$ = {
        load: null,
        change: null
    };
    var deleteBtn$ = null;
    var infoBtn$ = null;

    var cropModal$ = {
        modal: null,
        okBtn: null,
        closeBtn: null,
    };

    var infoModal$ = {
        modal: null,
        okBtn: null,
        uploadBtn: null,
        closeBtn: null,
    };

    var errorModal$ = {
        modal: null,
        okBtn: null,
        closeBtn: null,
    };

    var cropperImage$ = null;
    var cropperPreviewImage$ = null;
    var cropperPreviewOverlay$ = null;


    function readFileToBase64(file) {
        return new Promise(function (resolve, reject) {
            var fr = new FileReader();
            fr.addEventListener('load', function() {
                resolve(fr.result)
            });
            if (fr) {
                fr.readAsDataURL(file)
            }
        })
    }

    function checkFileExt(filename) {
        var ext = filename.split('.').pop();
        switch (ext) {
            case 'png':
            case 'jpeg':
            case 'jpg':
                return true;
            default:
                return false;
        }
    }

    function preventDefault(fn) {
        return function (evt) {
            evt.preventDefault();
            if (typeof fn === "function") {
                return fn(evt);
            }
        }
    }

    function onImageInputChange() {
        if (imageInput$ && imageInput$.files && imageInput$.files.length) {
            var fname = imageInput$.files[0].name;
            var fsize = imageInput$.files[0].size;

            if (fsize > FOTO_FILE_SIZE_LIMIT || !checkFileExt(fname)) {
                openErrorModal();
            } else {
                readFileToBase64(imageInput$.files[0]).then(function (fileUri) {
                    cropperData.fileSize = fsize;
                    cropperData.fileName = fname;
                    setImage(fileUri);
                    startCrop();
                });
            }
        }
    }

    function setImage(imageUri) {
        cropperData.uri = imageUri;
        cropperData.imgLoaded = true;
        switchOverlayVisibility();
        switchUploadText();
        switchDeleteBtnVisibility();
        cropperInstance.replace(imageUri);
    }

    function onCropChange(evt) {
        cropperData.selection.x = evt.detail.x;
        cropperData.selection.y = evt.detail.y;
        cropperData.selection.w = evt.detail.width;
        cropperData.selection.h = evt.detail.height;
    }

    function triggerUpload() {
        if (imageInput$) {
            imageInput$.click()
        }
    }

    function startCrop() {
        cropperData.prevSelection = Object.assign({}, cropperData.selection);

        // hack to fix cropper "responsiveness"
        cropModal$.modal.style.opacity = 0;
        cropperInstance.replace(cropperData.uri);
        restoreSelection(cropperData.prevSelection);
        cropModal$.modal.style.opacity = 1;

        openCropModal();
    }

    function restoreSelection(selection) {
        cropperInstance.setData({
            x: selection.x,
            y: selection.y,
            width: selection.w,
            height: selection.h
        });
    }

    function applyCrop(selection) {
        if (cropInput$ && cropInput$.x) {
            cropInput$.x.value = selection.x;
        }
        if (cropInput$ && cropInput$.y) {
            cropInput$.y.value = selection.y;
        }
        if (cropInput$ && cropInput$.w) {
            cropInput$.w.value = selection.w;
        }
        if (cropInput$ && cropInput$.h) {
            cropInput$.h.value = selection.h;
        }
    }

    function acceptCrop() {
        applyCrop(cropperData.selection);
        closeCropModal();
    }

    function discardCrop() {
        restoreSelection(cropperData.prevSelection);
        applyCrop(cropperData.prevSelection);
        closeCropModal();
    }

    function deletePhoto() {
        if (imageInput$) {
            imageInput$.value = "";
        }
        if (imageOldInput$) {
            imageOldInput$.value = "";
        }
        cropperData = _cropperData();
        cropperInstance.reset();
        cropperPreviewImage$.innerHTML = "";
        switchOverlayVisibility();
        switchDeleteBtnVisibility();
        switchUploadText();
    }

    function editPhoto() {
        if (cropperData.uri) {
            startCrop()
        }
    }

    function switchUploadText() {
        Object.keys(uploadTriggerBtnText$).forEach(function (k) {
            uploadTriggerBtnText$[k].style.display = "none";
        });

        if (cropperData.imgLoaded) {
            uploadTriggerBtnText$.change.style.display = "block";
        } else {
            uploadTriggerBtnText$.load.style.display = "block";
        }
    }

    function switchDeleteBtnVisibility() {
        deleteBtn$.style.display = "none";
        if (cropperData.imgLoaded) {
            deleteBtn$.style.display = "block";
        }
    }

    function switchOverlayVisibility() {
        if (cropperData.imgLoaded) {
            cropperPreviewOverlay$.classList.remove('image-cropper__preview__overlay--empty');
        } else {
            cropperPreviewOverlay$.classList.add("image-cropper__preview__overlay--empty");
        }
    }

    function openCropModal() {
        cropModal$.modal.style.display = "block";

    }

    function closeCropModal() {
        cropModal$.modal.style.display = "none";
    }

    function openInfoModal(from) {
        return function () {
            infoShownOnce = true;
            infoModal$.modal.style.display = "block";
            switch (from) {
                case 'info':
                    infoModal$.okBtn.style.display = "inline-block";
                    infoModal$.uploadBtn.style.display = "none";
                    break;
                case 'upload':
                    infoModal$.okBtn.style.display = "none";
                    infoModal$.uploadBtn.style.display = "inline-block";
                    break;
            }
        }
    }

    function closeInfoModal(){
        infoModal$.modal.style.display = "none";
    }

    function openErrorModal() {
        errorModal$.modal.style.display = "block";
    }

    function closeErrorModal() {
        errorModal$.modal.style.display = "none";
    }

    var selfInitDefaultOpts = {
        image: null,
        inputFile: null,
        inputCropX: null,
        inputCropY: null,
        inputCropW: null,
        inputCropH: null,
        infoBtn: null
    }

    self.init = function (elem, opts) {
        if (!elem) { return }
        infoShownOnce = false;
        opts = Object.assign({}, selfInitDefaultOpts, opts);

        infoBtn$ = opts.infoBtn;
        imageInput$ = opts.inputFile;
        imageOldInput$ = opts.inputFileOld;
        cropInput$ = {
            x: opts.inputCropX,
            y: opts.inputCropY,
            w: opts.inputCropW,
            h: opts.inputCropH,
        };

        uploadTriggerBtn$ = elem.querySelector('.image-cropper__upload-trigger');
        uploadTriggerBtn$.addEventListener('click', preventDefault(function () {
            var openInfo = openInfoModal('upload');
            if (!infoShownOnce) {
                openInfo();
            } else {
                triggerUpload();
            }
        }));

        uploadTriggerBtnText$ = {
            load: elem.querySelector('.image-cropper__upload-trigger__load'),
            change: elem.querySelector('.image-cropper__upload-trigger__change'),
        };
        uploadTriggerBtnText$.change.style.display = "none";

        deleteBtn$ = elem.querySelector(".image-cropper__delete");
        deleteBtn$.style.display = "none";
        deleteBtn$.addEventListener("click", preventDefault(deletePhoto));

        if (infoBtn$ !== null) {
            infoBtn$.addEventListener('click', preventDefault(openInfoModal('info')));
        }

        if (imageInput$ !== null) {
            imageInput$.addEventListener('change', onImageInputChange);
        }

        cropModal$ = {
            modal: elem.querySelector(".image-cropper__crop-modal"),
            okBtn: elem.querySelector(".image-cropper__crop-modal__ok"),
            closeBtn: elem.querySelector(".image-cropper__crop-modal .modal__closebtn"),
        };
        cropModal$.modal.style.display = "none";
        cropModal$.okBtn.addEventListener("click", preventDefault(acceptCrop));
        cropModal$.closeBtn.addEventListener("click", preventDefault(discardCrop));

        infoModal$ = {
            modal: elem.querySelector(".image-cropper__info-modal"),
            okBtn: elem.querySelector(".image-cropper__info-modal__ok"),
            uploadBtn: elem.querySelector(".image-cropper__info-modal__upload"),
            closeBtn: elem.querySelector(".image-cropper__info-modal .modal__closebtn"),
        };
        infoModal$.modal.style.display = "none";
        infoModal$.okBtn.addEventListener("click", preventDefault(closeInfoModal));
        infoModal$.uploadBtn.addEventListener("click", preventDefault(function () {
            closeInfoModal();
            triggerUpload();
        }));
        infoModal$.closeBtn.addEventListener("click", preventDefault(closeInfoModal));

        errorModal$ = {
            modal: elem.querySelector(".image-cropper__error-modal"),
            okBtn: elem.querySelector(".image-cropper__error-modal__ok"),
            closeBtn: elem.querySelector(".image-cropper__error-modal .modal__closebtn")
        };
        errorModal$.modal.style.display = "none";
        errorModal$.okBtn.addEventListener('click', preventDefault(closeErrorModal));
        errorModal$.closeBtn.addEventListener('click', preventDefault(closeErrorModal));


        cropperImage$ = elem.querySelector('.image-cropper__cropper-image');
        cropperPreviewImage$ = elem.querySelector('.image-cropper__preview__image');
        cropperPreviewOverlay$ = elem.querySelector(".image-cropper__preview__overlay");
        cropperPreviewOverlay$.addEventListener('click', preventDefault(editPhoto));

        cropperInstance = new Cropper(cropperImage$, {
            aspectRatio: 1,
            viewMode: 1,
            dragMode: 'move',
            movable: false,
            rotatable: false,
            scalable: false,
            zoomable: false,
            zoomOnTouch: false,
            cropBoxResizable: false,
            autoCropArea: 1,
            preview: cropperPreviewImage$,
            crop: onCropChange,
            // should update crop box with previous selections
            // except, it doesn't
            // see hack to fix responsiveness
            autoCrop: false,
            ready: function() {
                if (cropperData.prevSelection) {
                    cropperInstance.setCropBoxData({
                        x: cropperData.prevSelection.x,
                        y: cropperData.prevSelection.y,
                        width: cropperData.prevSelection.w,
                        height: cropperData.prevSelection.h
                    })
                }
                cropperInstance.crop();
            }
        });

        if (opts.image) {
            fetch(opts.image).then(function (res) {
                // server redirects if url is wrong
                if (/image\/.*/.test(res.headers.get('Content-Type'))) {
                    setImage(opts.image);
                }
            })
        }
    };

    return self;
})(jQuery,Modernizr, document, window);